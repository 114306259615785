nav {

  min-width: '100%';
  padding: 10px;
  align-self: flex-start;

  max-height: calc(100vh - 70px);
  overflow: auto;

}

.tdHead {
  background: lightgray;
  text-align: center;
  font-weight: 600;
}

.secHead {
  background: lightgray;
  text-align: center;
  font-weight: 600;
  color: black;
  padding: 5px;
  margin: 10px;
}

nav ul li {
  margin-bottom: 15px;
}

.head1 {
  margin-left: 10px;
  list-style-type: lower-roman;
}

.head2 {
  margin-left: 20px;
  list-style-type: square;
}

/* Table  */
.gridlines {
  color: black;
  border-collapse: collapse;
  width: 100%;
}

.gridlines-container {
  margin-bottom: 20px;
  padding: 10px;
  /* overflow-x: auto;  */
}

.gridlines th,
.gridlines td {
  border: 1px solid #00000070;
  padding: 3px;
  min-width: 40px;
  position: relative;
  /* Add a relative position to allow pseudo-element positioning */
  white-space: unset;
  /* Prevents wrapping to keep text on a single line */
  word-break: break-word;
  /* Breaks long words to prevent horizontal overflow */
  height: 25px;
  overflow: hidden;
  /* Handles overflow of long content */
}

.fb5 {
  font-weight: 500;
  color: black;
}

.gridlines th {
  background-color: #f2f2f2;
}

.para {
  color: black;
  margin: 10px
}

.text-under {
  text-decoration: underline;
  color: black;
  margin-left: 15px;
}

li.check {
  background: url('../../assets/images/report/check.png') no-repeat;
  /* Use a custom image for the check mark */
  padding-left: 20px;
  background-size: 17px 15px;
  margin: 5px;
}

li.cross {
  background: url('../../assets/images/report/cross.png') no-repeat;
  padding-left: 20px;
  background-size: 15px 15px;
  margin: 5px;
}

.pad30 {
  padding-left: 20px;
  list-style-type: none;
}



/* .empty-cell::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
  } */
/* Create a transparent content element for empty cells */
/* .gridlines td::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
  } */
/* .gridlines tr:nth-child(even) {
    background-color: #f2f2f2;
  } */

/* .gridlines tr:hover {
    background-color: #ddd;
  } */

.rfres-one::before {
  content: '\00a0\00a0\00a0';
}

.rfres-one {
  color: #005284;
  margin-bottom: 10px;
}